@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Black.eot");
  src: url("../../media/fonts/SofiaPro-Black.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Black.woff") format("woff"), url("../../media/fonts/SofiaPro-Black.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Black.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-BlackItalic.eot");
  src: url("../../media/fonts/SofiaPro-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-BlackItalic.woff") format("woff"), url("../../media/fonts/SofiaPro-BlackItalic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-BlackItalic.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Black.eot");
  src: url("../../media/fonts/SofiaPro-Black.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Black.woff") format("woff"), url("../../media/fonts/SofiaPro-Black.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Black.svg?#webfont") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-BlackItalic.eot");
  src: url("../../media/fonts/SofiaPro-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-BlackItalic.woff") format("woff"), url("../../media/fonts/SofiaPro-BlackItalic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-BlackItalic.svg?#webfont") format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Bold.eot");
  src: url("../../media/fonts/SofiaPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Bold.woff") format("woff"), url("../../media/fonts/SofiaPro-Bold.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Bold.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-BoldItalic.eot");
  src: url("../../media/fonts/SofiaPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-BoldItalic.woff") format("woff"), url("../../media/fonts/SofiaPro-BoldItalic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-BoldItalic.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-SemiBold.eot");
  src: url("../../media/fonts/SofiaPro-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-SemiBold.woff") format("woff"), url("../../media/fonts/SofiaPro-SemiBold.ttf") format("truetype"), url("../../media/fonts/SofiaPro-SemiBold.svg?#webfont") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-SemiBoldItalic.eot");
  src: url("../../media/fonts/SofiaPro-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-SemiBoldItalic.woff") format("woff"), url("../../media/fonts/SofiaPro-SemiBoldItalic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-SemiBoldItalic.svg?#webfont") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Medium.eot");
  src: url("../../media/fonts/SofiaPro-Medium.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Medium.woff") format("woff"), url("../../media/fonts/SofiaPro-Medium.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Medium.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-MediumItalic.eot");
  src: url("../../media/fonts/SofiaPro-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-MediumItalic.woff") format("woff"), url("../../media/fonts/SofiaPro-MediumItalic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-MediumItalic.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Regular.eot");
  src: url("../../media/fonts/SofiaPro-Regular.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Regular.woff") format("woff"), url("../../media/fonts/SofiaPro-Regular.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Regular.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Italic.eot");
  src: url("../../media/fonts/SofiaPro-Italic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Italic.woff") format("woff"), url("../../media/fonts/SofiaPro-Italic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Italic.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Light.eot");
  src: url("../../media/fonts/SofiaPro-Light.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Light.woff") format("woff"), url("../../media/fonts/SofiaPro-Light.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Light.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-LightItalic.eot");
  src: url("../../media/fonts/SofiaPro-LightItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-LightItalic.woff") format("woff"), url("../../media/fonts/SofiaPro-LightItalic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-LightItalic.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-Black.eot");
  src: url("../../media/fonts/Graphik-Black.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-Black.woff") format("woff"), url("../../media/fonts/Graphik-Black.ttf") format("truetype"), url("../../media/fonts/Graphik-Black.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-BlackItalic.eot");
  src: url("../../media/fonts/Graphik-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-BlackItalic.woff") format("woff"), url("../../media/fonts/Graphik-BlackItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-BlackItalic.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-Black.eot");
  src: url("../../media/fonts/Graphik-Black.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-Black.woff") format("woff"), url("../../media/fonts/Graphik-Black.ttf") format("truetype"), url("../../media/fonts/Graphik-Black.svg?#webfont") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-BlackItalic.eot");
  src: url("../../media/fonts/Graphik-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-BlackItalic.woff") format("woff"), url("../../media/fonts/Graphik-BlackItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-BlackItalic.svg?#webfont") format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-Bold.eot");
  src: url("../../media/fonts/Graphik-Bold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-Bold.woff") format("woff"), url("../../media/fonts/Graphik-Bold.ttf") format("truetype"), url("../../media/fonts/Graphik-Bold.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-BoldItalic.eot");
  src: url("../../media/fonts/Graphik-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-BoldItalic.woff") format("woff"), url("../../media/fonts/Graphik-BoldItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-BoldItalic.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-SemiBold.eot");
  src: url("../../media/fonts/Graphik-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-SemiBold.woff") format("woff"), url("../../media/fonts/Graphik-SemiBold.ttf") format("truetype"), url("../../media/fonts/Graphik-SemiBold.svg?#webfont") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-SemiBoldItalic.eot");
  src: url("../../media/fonts/Graphik-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-SemiBoldItalic.woff") format("woff"), url("../../media/fonts/Graphik-SemiBoldItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-SemiBoldItalic.svg?#webfont") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-Medium.eot");
  src: url("../../media/fonts/Graphik-Medium.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-Medium.woff") format("woff"), url("../../media/fonts/Graphik-Medium.ttf") format("truetype"), url("../../media/fonts/Graphik-Medium.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-MediumItalic.eot");
  src: url("../../media/fonts/Graphik-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-MediumItalic.woff") format("woff"), url("../../media/fonts/Graphik-MediumItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-MediumItalic.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-Regular.eot");
  src: url("../../media/fonts/Graphik-Regular.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-Regular.woff") format("woff"), url("../../media/fonts/Graphik-Regular.ttf") format("truetype"), url("../../media/fonts/Graphik-Regular.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-RegularItalic.eot");
  src: url("../../media/fonts/Graphik-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-RegularItalic.woff") format("woff"), url("../../media/fonts/Graphik-RegularItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-RegularItalic.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-Light.eot");
  src: url("../../media/fonts/Graphik-Light.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-Light.woff") format("woff"), url("../../media/fonts/Graphik-Light.ttf") format("truetype"), url("../../media/fonts/Graphik-Light.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-LightItalic.eot");
  src: url("../../media/fonts/Graphik-LightItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-LightItalic.woff") format("woff"), url("../../media/fonts/Graphik-LightItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-LightItalic.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-Black.eot");
  src: url("../../media/fonts/Inter-Black.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-Black.woff") format("woff"), url("../../media/fonts/Inter-Black.ttf") format("truetype"), url("../../media/fonts/Inter-Black.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-BlackItalic.eot");
  src: url("../../media/fonts/Inter-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-BlackItalic.woff") format("woff"), url("../../media/fonts/Inter-BlackItalic.ttf") format("truetype"), url("../../media/fonts/Inter-BlackItalic.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-ExtraBold.eot");
  src: url("../../media/fonts/Inter-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-ExtraBold.woff") format("woff"), url("../../media/fonts/Inter-ExtraBold.ttf") format("truetype"), url("../../media/fonts/Inter-ExtraBold.svg?#webfont") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-ExtraBoldItalic.eot");
  src: url("../../media/fonts/Inter-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-ExtraBoldItalic.woff") format("woff"), url("../../media/fonts/Inter-ExtraBoldItalic.ttf") format("truetype"), url("../../media/fonts/Inter-ExtraBoldItalic.svg?#webfont") format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-Bold.eot");
  src: url("../../media/fonts/Inter-Bold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-Bold.woff") format("woff"), url("../../media/fonts/Inter-Bold.ttf") format("truetype"), url("../../media/fonts/Inter-Bold.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-BoldItalic.eot");
  src: url("../../media/fonts/Inter-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-BoldItalic.woff") format("woff"), url("../../media/fonts/Inter-BoldItalic.ttf") format("truetype"), url("../../media/fonts/Inter-BoldItalic.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-SemiBold.eot");
  src: url("../../media/fonts/Inter-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-SemiBold.woff") format("woff"), url("../../media/fonts/Inter-SemiBold.ttf") format("truetype"), url("../../media/fonts/Inter-SemiBold.svg?#webfont") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-SemiBoldItalic.eot");
  src: url("../../media/fonts/Inter-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-SemiBoldItalic.woff") format("woff"), url("../../media/fonts/Inter-SemiBoldItalic.ttf") format("truetype"), url("../../media/fonts/Inter-SemiBoldItalic.svg?#webfont") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-Medium.eot");
  src: url("../../media/fonts/Inter-Medium.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-Medium.woff") format("woff"), url("../../media/fonts/Inter-Medium.ttf") format("truetype"), url("../../media/fonts/Inter-Medium.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-MediumItalic.eot");
  src: url("../../media/fonts/Inter-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-MediumItalic.woff") format("woff"), url("../../media/fonts/Inter-MediumItalic.ttf") format("truetype"), url("../../media/fonts/Inter-MediumItalic.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-Regular.eot");
  src: url("../../media/fonts/Inter-Regular.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-Regular.woff") format("woff"), url("../../media/fonts/Inter-Regular.ttf") format("truetype"), url("../../media/fonts/Inter-Regular.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-RegularItalic.eot");
  src: url("../../media/fonts/Inter-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-RegularItalic.woff") format("woff"), url("../../media/fonts/Inter-RegularItalic.ttf") format("truetype"), url("../../media/fonts/Inter-RegularItalic.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-Light.eot");
  src: url("../../media/fonts/Inter-Light.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-Light.woff") format("woff"), url("../../media/fonts/Inter-Light.ttf") format("truetype"), url("../../media/fonts/Inter-Light.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-LightItalic.eot");
  src: url("../../media/fonts/Inter-LightItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-LightItalic.woff") format("woff"), url("../../media/fonts/Inter-LightItalic.ttf") format("truetype"), url("../../media/fonts/Inter-LightItalic.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-Black.eot");
  src: url("../../media/fonts/CircularStd-Black.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-Black.woff") format("woff"), url("../../media/fonts/CircularStd-Black.ttf") format("truetype"), url("../../media/fonts/CircularStd-Black.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-BlackItalic.eot");
  src: url("../../media/fonts/CircularStd-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-BlackItalic.woff") format("woff"), url("../../media/fonts/CircularStd-BlackItalic.ttf") format("truetype"), url("../../media/fonts/CircularStd-BlackItalic.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-Bold.eot");
  src: url("../../media/fonts/CircularStd-Bold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-Bold.woff") format("woff"), url("../../media/fonts/CircularStd-Bold.ttf") format("truetype"), url("../../media/fonts/CircularStd-Bold.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-BoldItalic.eot");
  src: url("../../media/fonts/CircularStd-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-BoldItalic.woff") format("woff"), url("../../media/fonts/CircularStd-BoldItalic.ttf") format("truetype"), url("../../media/fonts/CircularStd-BoldItalic.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-Medium.eot");
  src: url("../../media/fonts/CircularStd-Medium.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-Medium.woff") format("woff"), url("../../media/fonts/CircularStd-Medium.ttf") format("truetype"), url("../../media/fonts/CircularStd-Medium.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-MediumItalic.eot");
  src: url("../../media/fonts/CircularStd-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-MediumItalic.woff") format("woff"), url("../../media/fonts/CircularStd-MediumItalic.ttf") format("truetype"), url("../../media/fonts/CircularStd-MediumItalic.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-Book.eot");
  src: url("../../media/fonts/CircularStd-Book.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-Book.woff") format("woff"), url("../../media/fonts/CircularStd-Book.ttf") format("truetype"), url("../../media/fonts/CircularStd-Book.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-BookItalic.eot");
  src: url("../../media/fonts/CircularStd-BookItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-BookItalic.woff") format("woff"), url("../../media/fonts/CircularStd-BookItalic.ttf") format("truetype"), url("../../media/fonts/CircularStd-BookItalic.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: italic;
}
/*!
 * Bootstrap Reboot v5.3.0 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #1E68F6;
  --bs-indigo: #6610f2;
  --bs-purple: #BD47FB;
  --bs-pink: #d63384;
  --bs-red: #FA2256;
  --bs-orange: #fd7e14;
  --bs-yellow: #F27F16;
  --bs-green: #11CABE;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #FFFFFF;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #246CF9;
  --bs-secondary: #141518;
  --bs-success: #11CABE;
  --bs-info: #1E68F6;
  --bs-warning: #F27F16;
  --bs-danger: #FA2256;
  --bs-light: #FFFFFF;
  --bs-dark: #141518;
  --bs-royal-blue: #246CF9;
  --bs-baby-blue-light: #EDFDFF;
  --bs-ultramarine-blue: #706DF8;
  --bs-ultramine-blue-pale: #E9E9FF;
  --bs-ultramarine-blue-light: #F4F3FF;
  --bs-white: #FFFFFF;
  --bs-grey-20: #E5E6ED;
  --bs-grey-40: #A5ADCF;
  --bs-grey-60: #5D6588;
  --bs-grey-80: #34384C;
  --bs-grey-100: #1E1F25;
  --bs-darker-grey: #141518;
  --bs-dark-transparent: rgba(47, 50, 65, 0.5);
  --bs-black: #000000;
  --bs-blue: #1E68F6;
  --bs-blue-pale: #BEE4FF;
  --bs-yellow: #F27F16;
  --bs-yellow-pale: #FFE4B1;
  --bs-green: #11CABE;
  --bs-green-pale: #CCF0E6;
  --bs-red: #FA2256;
  --bs-red-pale: #FFD1CB;
  --bs-primary-rgb: 36, 108, 249;
  --bs-secondary-rgb: 20, 21, 24;
  --bs-success-rgb: 17, 202, 190;
  --bs-info-rgb: 30, 104, 246;
  --bs-warning-rgb: 242, 127, 22;
  --bs-danger-rgb: 250, 34, 86;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 20, 21, 24;
  --bs-royal-blue-rgb: 36, 108, 249;
  --bs-baby-blue-light-rgb: 237, 253, 255;
  --bs-ultramarine-blue-rgb: 112, 109, 248;
  --bs-ultramine-blue-pale-rgb: 233, 233, 255;
  --bs-ultramarine-blue-light-rgb: 244, 243, 255;
  --bs-white-rgb: 255, 255, 255;
  --bs-grey-20-rgb: 229, 230, 237;
  --bs-grey-40-rgb: 165, 173, 207;
  --bs-grey-60-rgb: 93, 101, 136;
  --bs-grey-80-rgb: 52, 56, 76;
  --bs-grey-100-rgb: 30, 31, 37;
  --bs-darker-grey-rgb: 20, 21, 24;
  --bs-dark-transparent-rgb: 47, 50, 65;
  --bs-black-rgb: 0, 0, 0;
  --bs-blue-rgb: 30, 104, 246;
  --bs-blue-pale-rgb: 190, 228, 255;
  --bs-yellow-rgb: 242, 127, 22;
  --bs-yellow-pale-rgb: 255, 228, 177;
  --bs-green-rgb: 17, 202, 190;
  --bs-green-pale-rgb: 204, 240, 230;
  --bs-red-rgb: 250, 34, 86;
  --bs-red-pale-rgb: 255, 209, 203;
  --bs-primary-text-emphasis: #0e2b64;
  --bs-secondary-text-emphasis: #08080a;
  --bs-success-text-emphasis: #07514c;
  --bs-info-text-emphasis: #0c2a62;
  --bs-warning-text-emphasis: #613309;
  --bs-danger-text-emphasis: #640e22;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #d3e2fe;
  --bs-secondary-bg-subtle: #d0d0d1;
  --bs-success-bg-subtle: #cff4f2;
  --bs-info-bg-subtle: #d2e1fd;
  --bs-warning-bg-subtle: #fce5d0;
  --bs-danger-bg-subtle: #fed3dd;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #a7c4fd;
  --bs-secondary-border-subtle: #a1a1a3;
  --bs-success-border-subtle: #a0eae5;
  --bs-info-border-subtle: #a5c3fb;
  --bs-warning-border-subtle: #facca2;
  --bs-danger-border-subtle: #fda7bb;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Sofia Pro", sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.88;
  --bs-body-color: #FFFFFF;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-body-bg: #141518;
  --bs-body-bg-rgb: 20, 21, 24;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(255, 255, 255, 0.75);
  --bs-secondary-color-rgb: 255, 255, 255;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(255, 255, 255, 0.5);
  --bs-tertiary-color-rgb: 255, 255, 255;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: #FFFFFF;
  --bs-link-color: #246CF9;
  --bs-link-color-rgb: 36, 108, 249;
  --bs-link-decoration: none;
  --bs-link-hover-color: #1d56c7;
  --bs-link-hover-color-rgb: 29, 86, 199;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fce5d0;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #E5E6ED;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 10px;
  --bs-border-radius-sm: 5px;
  --bs-border-radius-lg: 15px;
  --bs-border-radius-xl: 20px;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0px 12px 36px rgba(159, 165, 174, 0.08);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(36, 108, 249, 0.25);
  --bs-form-valid-color: #11CABE;
  --bs-form-valid-border-color: #11CABE;
  --bs-form-invalid-color: #FA2256;
  --bs-form-invalid-border-color: #FA2256;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #FFFFFF;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #7ca7fb;
  --bs-secondary-text-emphasis: #727374;
  --bs-success-text-emphasis: #70dfd8;
  --bs-info-text-emphasis: #78a4fa;
  --bs-warning-text-emphasis: #f7b273;
  --bs-danger-text-emphasis: #fc7a9a;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #071632;
  --bs-secondary-bg-subtle: #040405;
  --bs-success-bg-subtle: #032826;
  --bs-info-bg-subtle: #061531;
  --bs-warning-bg-subtle: #301904;
  --bs-danger-bg-subtle: #320711;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #164195;
  --bs-secondary-border-subtle: #0c0d0e;
  --bs-success-border-subtle: #0a7972;
  --bs-info-border-subtle: #123e94;
  --bs-warning-border-subtle: #914c0d;
  --bs-danger-border-subtle: #961434;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #7ca7fb;
  --bs-link-hover-color: #96b9fc;
  --bs-link-color-rgb: 124, 167, 251;
  --bs-link-hover-color-rgb: 150, 185, 252;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #70dfd8;
  --bs-form-valid-border-color: #70dfd8;
  --bs-form-invalid-color: #fc7a9a;
  --bs-form-invalid-border-color: #fc7a9a;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1 {
  font-size: calc(1.50625rem + 3.075vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 3.8125rem;
  }
}

h2 {
  font-size: calc(1.43125rem + 2.175vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 3.0625rem;
  }
}

h3 {
  font-size: calc(1.36875rem + 1.425vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 2.4375rem;
  }
}

h4 {
  font-size: calc(1.31875rem + 0.825vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.9375rem;
  }
}

h5 {
  font-size: calc(1.28125rem + 0.375vw);
}
@media (min-width: 1200px) {
  h5 {
    font-size: 1.5625rem;
  }
}

h6 {
  font-size: 1.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 5px;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  color: #FFFFFF;
  letter-spacing: -0.01em;
}

* {
  font-smooth: always;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  scroll-behavior: smooth !important;
}

a {
  font-weight: 700;
}

h1 {
  font-size: 3.8125rem;
  line-height: 1.1;
}
@media (max-width: 575.98px) {
  h1 {
    font-size: 2.125rem;
    line-height: 1.1;
  }
}

h2 {
  font-size: 3.0625rem;
  line-height: 1.1;
}
@media (max-width: 575.98px) {
  h2 {
    font-size: 1.75rem;
    line-height: 1.1;
  }
}

h3 {
  font-size: 2.4375rem;
  line-height: 1.1;
}
@media (max-width: 575.98px) {
  h3 {
    font-size: 1.375rem;
    line-height: 1.1;
  }
}

h4 {
  font-size: 1.9375rem;
  line-height: 1.2;
}
@media (max-width: 575.98px) {
  h4 {
    font-size: 1.125rem;
    line-height: 1.2;
  }
}

h5 {
  font-size: 1.5625rem;
  line-height: 1.2;
}
@media (max-width: 575.98px) {
  h5 {
    font-size: 1rem;
    line-height: 1.2;
  }
}

h6 {
  font-size: 1.25rem;
  line-height: 1.2;
}
@media (max-width: 575.98px) {
  h6 {
    font-size: 0.875rem;
    line-height: 1.2;
  }
}

@media (max-width: 767.98px) {
  .container {
    padding: 0 1.5rem;
  }
}
@media (max-width: 374.98px) {
  .container {
    padding: 0 1rem;
  }
}
.container.container--dashboard {
  max-width: 90rem;
  padding: 0 2.75rem;
}
@media (max-width: 1199.98px) {
  .container.container--dashboard {
    padding: 0 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .container.container--dashboard {
    padding: 0 2rem;
  }
}
@media (max-width: 767.98px) {
  .container.container--dashboard {
    padding: 0 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .container.container--dashboard {
    padding: 0 1rem;
  }
}
.container.container--dashboard-nav {
  max-width: 100% !important;
  padding: 0 2.75rem;
}
@media (max-width: 1199.98px) {
  .container.container--dashboard-nav {
    padding: 0 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .container.container--dashboard-nav {
    padding: 0 2rem;
  }
}
@media (max-width: 767.98px) {
  .container.container--dashboard-nav {
    padding: 0 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .container.container--dashboard-nav {
    padding: 0 1rem;
  }
}

.fd-xl {
  font-size: 3.5rem;
  line-height: 1.2;
  margin: 0;
  font-weight: 500;
  font-family: "Graphik", sans-serif;
}
.fd-xl--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fd-xl {
    font-size: 2.4375rem;
  }
}

.fd-lg {
  font-size: 2.4375rem;
  line-height: 1.2;
  margin: 0;
  font-weight: 500;
  font-family: "Graphik", sans-serif;
}
.fd-lg--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fd-lg {
    font-size: 1.75rem;
  }
}

.fd-md {
  font-size: 1.5625rem;
  line-height: 1.1;
  margin: 0;
  font-weight: 400;
  font-family: "Graphik", sans-serif;
}
.fd-md--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fd-md {
    font-size: 1.25rem;
  }
}

.fd-sm {
  font-size: 1.25rem;
  line-height: 1.1;
  margin: 0;
  font-weight: 400;
  font-family: "Graphik", sans-serif;
}
.fd-sm--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fd-sm {
    font-size: 1.125rem;
  }
}

.fb-lg {
  font-size: 1.125rem;
  line-height: 1.5;
  margin: 0;
  font-weight: 400;
  font-family: "Graphik", sans-serif;
}
.fb-lg--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fb-lg {
    font-size: 1rem;
  }
}

.fb-regular {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  font-weight: 400;
  font-family: "Graphik", sans-serif;
}
.fb-regular--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fb-regular {
    font-size: 0.875rem;
  }
}

.fb-sm {
  font-size: 0.875rem;
  line-height: 1.6;
  margin: 0;
  font-weight: 400;
  font-family: "Graphik", sans-serif;
}
.fb-sm--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fb-sm {
    font-size: 0.75rem;
  }
}

.fb-xs {
  font-size: 0.75rem;
  line-height: 1.6;
  margin: 0;
  font-weight: 400;
  font-family: "Graphik", sans-serif;
}
.fb-xs--bold {
  font-weight: 600;
}

.text-bullish {
  color: #11CABE;
}

.text-bearish {
  color: #FA2256;
}

.text-green-light {
  color: #30E0A1;
}

@-webkit-keyframes ScaleBig {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  75% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ScaleBig {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  75% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes ScaleBigHideFirst {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    opacity: 0.25;
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0.5;
  }
  75% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    opacity: 0.75;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes ScaleBigHideFirst {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    opacity: 0.25;
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0.5;
  }
  75% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    opacity: 0.75;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.ScaleBig {
  animation: ScaleBig 0.8s linear;
}

.ScaleBigHideFirst {
  animation: ScaleBigHideFirst 0.8s linear;
}

body {
  background: #1E1F25;
}

.hero-v2 {
  position: relative;
  z-index: 1;
}
.hero-v2 .hero__background {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 90rem;
  overflow: hidden;
  pointer-events: none;
  margin: 0 auto;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .hero-v2 .hero__background {
    display: none;
  }
}
.hero-v2 .hero__background.hero__background--grid {
  top: -6.375rem;
}
@media (max-width: 991.98px) {
  .hero-v2 .hero__background.hero__background--grid {
    display: -8.125rem;
  }
}
.hero-v2 .hero__background.hero__background--grid .hero__grid-bg {
  position: relative;
  left: -2.5rem;
  display: block;
  margin: 0 auto;
  max-width: 72.25rem;
}
.hero-v2 .hero__background.hero__background--pattern {
  top: -6.375rem;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  overflow: hidden;
}
.hero-v2 .hero__background.hero__background--pattern .hero__pattern-bg {
  position: relative;
  left: -2.5rem;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
}
.hero-v2 .hero__background--mobile {
  display: none;
}
@media (max-width: 767.98px) {
  .hero-v2 .hero__background--mobile {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 33.75rem;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    z-index: -1;
  }
}
.hero-v2 .hero__background--mobile.hero__background--grid {
  top: -6rem;
}
.hero-v2 .hero__background--mobile.hero__background--grid .hero__grid-bg {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.hero-v2 .hero__background--mobile.hero__background--pattern {
  top: -4.75rem;
  right: 0rem;
  width: 100%;
  left: unset;
  margin: 0;
  margin-left: auto;
}
.hero-v2 .hero__background--mobile.hero__background--pattern .hero__pattern-bg {
  position: relative;
  display: block;
  margin-left: auto;
  width: 100%;
  height: auto;
  max-width: 23.3125rem;
}
.hero-v2 .hero__container {
  position: relative;
  padding: 6rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}
@media (max-width: 991.98px) {
  .hero-v2 .hero__container {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 767.98px) {
  .hero-v2 .hero__container {
    padding: 1rem 0 2.25rem;
  }
}
@media (max-width: 991.98px) {
  .hero-v2 .hero__container .hero__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.hero-v2 .hero__container .hero__content .hero__title {
  color: #FFFFFF;
  width: 100%;
  max-width: 36.25rem;
  margin-bottom: 1.5rem;
}
.hero-v2 .hero__container .hero__content .hero__subtitle {
  color: #E5E6ED;
  margin-bottom: 3rem;
}
@media (max-width: 767.98px) {
  .hero-v2 .hero__container .hero__content .hero__subtitle {
    margin-bottom: 2rem;
  }
}
.hero-v2 .hero__container .hero__content .hero__link {
  max-width: max-content;
  padding-left: 4.6875rem;
  padding-right: 4.6875rem;
}
@media (max-width: 767.98px) {
  .hero-v2 .hero__container .hero__content .hero__link {
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .hero-v2 .hero__container .hero__illustration {
    display: none;
  }
}
.hero-v2 .hero__container .hero__illustration img {
  pointer-events: none;
  width: auto;
  height: auto;
  max-width: 35rem;
}
@media (max-width: 1199.98px) {
  .hero-v2 .hero__container .hero__illustration img {
    max-width: 30rem;
  }
}
@media (max-width: 1063.98px) {
  .hero-v2 .hero__container .hero__illustration img {
    max-width: 28.125rem;
  }
}
.hero-v2 .crypto-graph {
  position: relative;
  max-width: 90rem;
  padding: 0;
  width: 100%;
  overflow: hidden;
  /* the slides */
  /* the parent */
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items {
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr;
  max-width: 21.625rem;
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items {
    max-width: 20.3125rem;
    padding: 1rem;
  }
}
@media (max-width: 374.98px) {
  .hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items {
    max-width: 18.125rem;
  }
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(47, 50, 65, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 0.5rem;
  z-index: -1;
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item__crypto-icon {
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 100%;
}
@media (max-width: 767.98px) {
  .hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item__crypto-icon {
    width: 2rem;
    height: 2rem;
  }
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item__graph {
  grid-column: 2/3;
  grid-row: 1/3;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item__graph .crypto-graph-item-graph__img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item__graph .crypto-graph-item-graph__inflution {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.25rem;
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item__graph .crypto-graph-item-graph__inflution.crypto-graph-item-graph__inflution--bullish {
  color: #11CABE;
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item__graph .crypto-graph-item-graph__inflution.crypto-graph-item-graph__inflution--bearish {
  color: #FA2256;
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item--fullname {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item--fullname .crypto-graph-item__name {
  color: #A5ADCF;
  margin-top: auto;
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item--price-codename {
  padding-top: 0.6875rem;
  grid-column: 1/-1;
  grid-row: 3/4;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
@media (max-width: 767.98px) {
  .hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item--price-codename {
    padding-top: 0;
  }
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item--price-codename .crypto-graph-item__price {
  color: #FFFFFF;
}
.hero-v2 .crypto-graph .crypto-graph-carousel .crypto-graph-carousel__items .crypto-graph-item--price-codename .crypto-graph-item__code {
  color: #5D6588;
  text-transform: uppercase;
}
.hero-v2 .crypto-graph .slick-slide {
  margin: 0 0.75rem;
}
@media (max-width: 479.98px) {
  .hero-v2 .crypto-graph .slick-slide {
    margin: 0 0.875rem;
  }
}
@media (max-width: 374.98px) {
  .hero-v2 .crypto-graph .slick-slide {
    margin: 0 1rem;
  }
}
.hero-v2 .crypto-graph .slick-list {
  margin: 0 -0.75rem;
}
@media (max-width: 479.98px) {
  .hero-v2 .crypto-graph .slick-list {
    margin: 0 -0.875rem;
  }
}
@media (max-width: 374.98px) {
  .hero-v2 .crypto-graph .slick-list {
    margin: 0 -1rem;
  }
}
.hero-v2 .crypto-graph .slick__pagination {
  position: relative;
  margin-top: 1.5rem;
}
@media (max-width: 767.98px) {
  .hero-v2 .crypto-graph .slick__pagination {
    margin-top: 1rem;
  }
}

.feature {
  position: relative;
  z-index: 1;
  padding: 7.5rem 0;
}
@media (max-width: 767.98px) {
  .feature {
    padding: 4rem 0;
  }
}
.feature .feature__background {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 90rem;
  overflow: hidden;
  pointer-events: none;
  margin: 0 auto;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .feature .feature__background {
    display: none;
  }
}
.feature .feature__background.feature__background--pattern {
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  overflow: hidden;
}
.feature .feature__background.feature__background--pattern .feature__pattern-bg {
  position: absolute;
  right: 3rem;
  top: 6.25rem;
  bottom: 0;
  margin: auto 0;
  display: block;
  width: 100%;
  max-width: 31.6875rem;
  height: auto;
}
@media (max-width: 1199.98px) {
  .feature .feature__background.feature__background--pattern .feature__pattern-bg {
    right: -3.75rem;
  }
}
@media (max-width: 1063.98px) {
  .feature .feature__background.feature__background--pattern .feature__pattern-bg {
    top: 12.5rem;
    margin: unset;
  }
}
.feature .feature__background--mobile {
  display: none;
}
@media (max-width: 767.98px) {
  .feature .feature__background--mobile {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 33.75rem;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    z-index: -1;
  }
}
.feature .feature__background--mobile.feature__background--pattern {
  top: 5.625rem;
  right: 0rem;
  width: 100%;
  left: unset;
  margin: 0;
  margin-left: auto;
}
.feature .feature__background--mobile.feature__background--pattern .feature__pattern-bg {
  position: relative;
  display: block;
  margin-left: auto;
  width: 100%;
  height: auto;
  max-width: 13.625rem;
}
.feature .feature__container {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 4.25rem;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1199.98px) {
  .feature .feature__container {
    gap: 2.5rem;
  }
}
@media (max-width: 1063.98px) {
  .feature .feature__container {
    grid-template-columns: 1fr;
  }
}
.feature .feature__group {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: center;
  gap: 1.5rem;
}
@media (max-width: 1063.98px) {
  .feature .feature__group {
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
  }
}
@media (max-width: 991.98px) {
  .feature .feature__group {
    gap: 1rem;
  }
}
@media (max-width: 767.98px) {
  .feature .feature__group {
    grid-template-columns: 1fr;
  }
}
.feature .feature__group .feature__items {
  padding: 2.5rem 1.75rem;
  height: auto;
  width: 17.8125rem;
  min-height: 20.5625rem;
  border-radius: 0.75rem;
  background: rgba(47, 50, 65, 0.5);
  backdrop-filter: blur(0.125rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (max-width: 1063.98px) {
  .feature .feature__group .feature__items {
    width: 100%;
    height: 100%;
  }
}
.feature .feature__group .feature__items.feature__items--3 {
  grid-row: 1/3;
  grid-column: 2/3;
  height: max-content;
  margin: auto 0;
}
@media (max-width: 1063.98px) {
  .feature .feature__group .feature__items.feature__items--3 {
    grid-row: auto;
    grid-column: auto;
    margin: unset;
  }
}
@media (max-width: 767.98px) {
  .feature .feature__group .feature__items {
    width: 100%;
    padding: 2rem 1.75rem;
    min-height: unset;
  }
}
.feature .feature__group .feature__items .feature-items__img {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 5.6875rem;
  height: 5.6875rem;
  z-index: 1;
}
.feature .feature__group .feature__items .feature-items__img::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #34384C;
  backdrop-filter: blur(0.4375rem);
  border-radius: 100%;
  z-index: 1;
}
.feature .feature__group .feature__items .feature-items__img img {
  width: 2.75rem;
  height: 2.75rem;
  z-index: 2;
}
.feature .feature__group .feature__items .feature-items__title {
  color: #FFFFFF;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
.feature .feature__group .feature__items .feature-items__desc {
  color: #A5ADCF;
}
.feature .feature__content {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1063.98px) {
  .feature .feature__content {
    grid-row: 1/2;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}
.feature .feature__content .feature__title {
  max-width: 28rem;
  margin-bottom: 1.5rem;
  color: #FFFFFF;
}
@media (max-width: 1063.98px) {
  .feature .feature__content .feature__title {
    max-width: 36.25rem;
  }
}
@media (max-width: 767.98px) {
  .feature .feature__content .feature__title {
    margin-bottom: 1rem;
  }
}
.feature .feature__content .feature__subtitle {
  margin-bottom: 2rem;
  max-width: 28rem;
  color: #E5E6ED;
}
@media (max-width: 767.98px) {
  .feature .feature__content .feature__subtitle {
    margin-bottom: 1.5rem;
  }
}
.feature .feature__content .feature__button {
  max-width: max-content;
  padding-left: 3.4375rem;
  padding-right: 3.4375rem;
}
@media (max-width: 767.98px) {
  .feature .feature__content .feature__button {
    padding: 0.75rem 3rem;
  }
}

.exchange {
  position: relative;
  padding-bottom: 1.6875rem;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .exchange {
    padding-bottom: 1.625rem;
  }
}
.exchange .exchange__background {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 90rem;
  overflow: hidden;
  pointer-events: none;
  margin: 0 auto;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .exchange .exchange__background {
    display: none;
  }
}
.exchange .exchange__background.exchange__background--pattern {
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  overflow: hidden;
}
.exchange .exchange__background.exchange__background--pattern .exchange__pattern-bg {
  position: relative;
  top: 7rem;
  left: -1rem;
  display: block;
  width: 100%;
  max-width: 9.5rem;
  height: auto;
}
.exchange .exchange__background--mobile {
  display: none;
}
@media (max-width: 767.98px) {
  .exchange .exchange__background--mobile {
    display: block;
    position: absolute;
    width: 100%;
    max-width: 33.75rem;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
  }
}
.exchange .exchange__background--mobile.exchange__background--pattern {
  top: 8rem;
  left: 0rem;
  right: unset;
  width: 100%;
  margin: 0;
  margin-left: auto;
}
.exchange .exchange__background--mobile.exchange__background--pattern .exchange__pattern-bg {
  position: relative;
  display: block;
  margin-right: auto;
  width: 100%;
  height: auto;
  max-width: 6.6875rem;
}
.exchange .exchange__container {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 5.9375rem;
}
@media (max-width: 1199.98px) {
  .exchange .exchange__container {
    gap: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .exchange .exchange__container {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.exchange .exchange__content {
  position: relative;
}
.exchange .exchange__content .exchange__title {
  margin-bottom: 1.5rem;
}
.exchange .exchange__content .exchange__subtitle {
  margin-bottom: 2.5rem;
  max-width: 31.3125rem;
  color: #E5E6ED;
}
@media (max-width: 991.98px) {
  .exchange .exchange__content .exchange__subtitle {
    margin: 0 auto;
  }
}
.exchange .exchange__content .exchange__groups {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media (max-width: 991.98px) {
  .exchange .exchange__content .exchange__groups {
    gap: 1rem;
    margin-top: 1.5rem;
  }
}
.exchange .exchange__content .exchange__groups .exchange__items {
  display: flex;
  align-items: center;
  gap: 2rem;
}
@media (max-width: 991.98px) {
  .exchange .exchange__content .exchange__groups .exchange__items {
    gap: 1.5rem;
    grid-template-columns: max-content 16.25rem;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .exchange .exchange__content .exchange__groups .exchange__items {
    grid-template-columns: minmax(4rem, max-content) 1fr;
  }
}
.exchange .exchange__content .exchange__groups .exchange__items.exchange__items--1 {
  margin-left: 2.375rem;
}
@media (max-width: 1199.98px) {
  .exchange .exchange__content .exchange__groups .exchange__items.exchange__items--1 {
    margin: 0;
  }
}
.exchange .exchange__content .exchange__groups .exchange__items.exchange__items--2 {
  margin: 0 auto;
}
@media (max-width: 1199.98px) {
  .exchange .exchange__content .exchange__groups .exchange__items.exchange__items--2 {
    margin: 0;
  }
}
.exchange .exchange__content .exchange__groups .exchange__items.exchange__items--3 {
  margin-left: auto;
}
@media (max-width: 1199.98px) {
  .exchange .exchange__content .exchange__groups .exchange__items.exchange__items--3 {
    margin: 0;
  }
}
.exchange .exchange__content .exchange__groups .exchange__items .exchange-items__icons {
  position: relative;
  z-index: 1;
  height: 5.6875rem;
  width: 5.6875rem;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767.98px) {
  .exchange .exchange__content .exchange__groups .exchange__items .exchange-items__icons {
    height: 4rem;
    width: 100%;
    max-width: 4rem;
  }
}
.exchange .exchange__content .exchange__groups .exchange__items .exchange-items__icons::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(47, 50, 65, 0.5);
  backdrop-filter: blur(7px);
  border-radius: 100%;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .exchange .exchange__content .exchange__groups .exchange__items .exchange-items__icons::before {
    height: 4rem;
    width: 4rem;
  }
}
.exchange .exchange__content .exchange__groups .exchange__items .exchange-items__icons img {
  height: 2.75rem;
  width: 2.75rem;
}
@media (max-width: 767.98px) {
  .exchange .exchange__content .exchange__groups .exchange__items .exchange-items__icons img {
    height: 1.934375rem;
    width: 1.934375rem;
  }
}
@media (max-width: 991.98px) {
  .exchange .exchange__content .exchange__groups .exchange__items .exchange-items__detail {
    width: 100%;
    max-width: 16.875rem;
    text-align: left;
  }
}
@media (max-width: 767.98px) {
  .exchange .exchange__content .exchange__groups .exchange__items .exchange-items__detail h5 {
    font-size: 1.125rem;
  }
}
.exchange .buy-sell-cards--v2 {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 27.9375rem;
}
@media (max-width: 991.98px) {
  .exchange .buy-sell-cards--v2 {
    margin: 0 auto;
  }
}
.exchange .buy-sell-cards--v2 .buy-sell-cards__container {
  position: relative;
  display: block;
  margin: 0 auto;
  margin-top: 3rem;
  width: 100%;
  max-width: 28rem;
  height: auto;
  background: rgba(47, 50, 65, 0.5);
  backdrop-filter: blur(2rem);
  border-radius: 1.25rem;
  padding: 2rem;
}
@media (max-width: 991.98px) {
  .exchange .buy-sell-cards--v2 .buy-sell-cards__container {
    margin-top: 0rem;
  }
}
@media (max-width: 767.98px) {
  .exchange .buy-sell-cards--v2 .buy-sell-cards__container {
    padding: 1rem 1rem 1.5rem;
  }
}
.exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  border: none;
  margin: 0 3.125rem 3rem;
}
@media (max-width: 767.98px) {
  .exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__header {
    margin: 0 3.375rem 1.5rem;
  }
}
.exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__header .separator {
  height: 2rem;
  width: 1px;
  background: #34384C;
}
.exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__header .buy-sell-cards__link {
  background: transparent;
  border: none;
  padding: 0;
  text-transform: capitalize;
  color: #5D6588;
}
@media (max-width: 767.98px) {
  .exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__header .buy-sell-cards__link {
    font-size: 0.875rem;
  }
}
.exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__header .buy-sell-cards__link.active {
  color: #1A82FF;
}
.exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__detail {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 2.5rem;
  margin-bottom: 2.1875rem;
}
@media (max-width: 767.98px) {
  .exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__detail {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__detail .notes {
    text-align: left;
  }
}
.exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__detail .value {
  display: flex;
  gap: 0.875rem;
  margin-top: 0.5rem;
  align-items: flex-end;
  justify-content: center;
}
@media (max-width: 767.98px) {
  .exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__detail .value {
    gap: 0.25rem;
    margin-top: 0rem;
    font-size: 1.125rem;
  }
}
.exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__detail .currency {
  color: #5D6588;
  position: relative;
  top: 0.1875rem;
}
.exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__detail .rewards {
  color: #A5ADCF;
}
@media (max-width: 767.98px) {
  .exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__detail .rewards {
    font-size: 0.75rem;
  }
}
.exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__forms {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media (max-width: 767.98px) {
  .exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__forms {
    gap: 1rem;
  }
}
.exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__forms .forms-purchase__box .forms-purchase {
  background: transparent !important;
}
.exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__forms .btn {
  width: 100%;
}
@media (max-width: 767.98px) {
  .exchange .buy-sell-cards--v2 .buy-sell-cards__container .buy-sell-cards__forms .btn {
    margin-top: 3rem;
  }
}

.why-choose-us--v2 {
  position: relative;
  padding: 7.5rem 0 10rem;
  z-index: 1;
}
@media (max-width: 1063.98px) {
  .why-choose-us--v2 {
    padding: 4rem 0;
  }
}
.why-choose-us--v2 .why-choose-us__background {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 90rem;
  overflow: hidden;
  pointer-events: none;
  margin: 0 auto;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .why-choose-us--v2 .why-choose-us__background {
    display: none;
  }
}
.why-choose-us--v2 .why-choose-us__background.why-choose-us__background--pattern {
  top: 0;
  bottom: 0;
  right: 0;
  left: unset;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  overflow: hidden;
}
.why-choose-us--v2 .why-choose-us__background.why-choose-us__background--pattern .why-choose-us__pattern-bg {
  position: relative;
  top: 10.4375rem;
  right: -2rem;
  margin-left: auto;
  display: block;
  width: 100%;
  max-width: 21.5625rem;
  height: auto;
}
.why-choose-us--v2 .why-choose-us__background--mobile {
  display: none;
}
@media (max-width: 767.98px) {
  .why-choose-us--v2 .why-choose-us__background--mobile {
    display: block;
    position: absolute;
    width: 100%;
    max-width: 33.75rem;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
  }
}
.why-choose-us--v2 .why-choose-us__background--mobile.why-choose-us__background--pattern {
  top: 3.4375rem;
  right: 0rem;
  left: unset;
  width: 100%;
  margin: 0;
  margin-left: auto;
}
.why-choose-us--v2 .why-choose-us__background--mobile.why-choose-us__background--pattern .why-choose-us__pattern-bg {
  position: relative;
  display: block;
  margin-left: auto;
  width: 100%;
  height: auto;
  max-width: 12.9375rem;
}
.why-choose-us--v2 .why-choose-us__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.625rem;
  align-items: center;
}
@media (max-width: 1199.98px) {
  .why-choose-us--v2 .why-choose-us__container {
    gap: 2rem;
  }
}
@media (max-width: 991.98px) {
  .why-choose-us--v2 .why-choose-us__container {
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }
}
.why-choose-us--v2 .why-choose-us__illustration {
  display: block;
  width: 100%;
  max-width: 36.375rem;
}
@media (max-width: 991.98px) {
  .why-choose-us--v2 .why-choose-us__illustration {
    grid-row: 2/3;
    margin: 0 auto;
  }
}
.why-choose-us--v2 .why-choose-us__title {
  max-width: 39.75rem;
  margin-bottom: 1.5rem;
  color: #FFFFFF;
}
@media (max-width: 991.98px) {
  .why-choose-us--v2 .why-choose-us__title {
    text-align: center;
    margin: 0 auto 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .why-choose-us--v2 .why-choose-us__title {
    margin: 0 auto 1rem;
  }
}
.why-choose-us--v2 .why-choose-us__subtitle {
  max-width: 34.125rem;
  color: #E5E6ED;
}
@media (max-width: 991.98px) {
  .why-choose-us--v2 .why-choose-us__subtitle {
    text-align: center;
    margin: 0 auto;
  }
}
.why-choose-us--v2 .why-choose-us__groups {
  margin: 2.5rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
}
@media (max-width: 767.98px) {
  .why-choose-us--v2 .why-choose-us__groups {
    gap: 1rem;
  }
}
.why-choose-us--v2 .why-choose-us__groups .why-choose-us__items {
  width: 100%;
  text-align: left;
  max-width: 100%;
  display: grid;
  grid-template-columns: 6.9375rem 1fr;
  gap: 2rem;
}
@media (max-width: 991.98px) {
  .why-choose-us--v2 .why-choose-us__groups .why-choose-us__items {
    grid-template-columns: 6.9375rem 19.3125rem;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .why-choose-us--v2 .why-choose-us__groups .why-choose-us__items {
    grid-template-columns: 4.5rem 1fr;
    align-items: flex-start;
    gap: 1rem;
    justify-content: center;
  }
}
.why-choose-us--v2 .why-choose-us__groups .why-choose-us__items .why-choose-us-items__header {
  width: 6.9375rem;
  height: 6.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  background: rgba(47, 50, 65, 0.5);
}
@media (max-width: 767.98px) {
  .why-choose-us--v2 .why-choose-us__groups .why-choose-us__items .why-choose-us-items__header {
    width: 4.5rem;
    height: 4.5rem;
  }
}
.why-choose-us--v2 .why-choose-us__groups .why-choose-us__items .why-choose-us-items__header img {
  width: 2.75rem;
  height: 2.75rem;
}
@media (max-width: 767.98px) {
  .why-choose-us--v2 .why-choose-us__groups .why-choose-us__items .why-choose-us-items__header img {
    width: 1.78375rem;
    height: 1.78375rem;
  }
}
.why-choose-us--v2 .why-choose-us__groups .why-choose-us__items .why-choose-us-item__title {
  color: #FFFFFF;
}
.why-choose-us--v2 .why-choose-us__groups .why-choose-us__items .why-choose-us-item__desc {
  color: #A5ADCF;
  margin-top: 0.5rem;
  max-width: 19.3125rem;
}

.testimonial {
  position: relative;
  z-index: 1;
}
.testimonial .testimonial__container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 32rem;
  gap: 3.125rem;
  align-items: center;
}
@media (max-width: 1199.98px) {
  .testimonial .testimonial__container {
    gap: 2.5rem;
  }
}
@media (max-width: 1063.98px) {
  .testimonial .testimonial__container {
    grid-template-columns: 1fr;
  }
}
.testimonial .testimonial__container .testimonial__title {
  color: #FFFFFF;
  margin-bottom: 2.5rem;
}
@media (max-width: 1063.98px) {
  .testimonial .testimonial__container .testimonial__title {
    display: none;
  }
}
.testimonial .testimonial__container .testimonial__title--mobile {
  color: #FFFFFF;
  display: none;
}
@media (max-width: 1063.98px) {
  .testimonial .testimonial__container .testimonial__title--mobile {
    display: block;
    text-align: center;
  }
}
.testimonial .testimonial__container .testimonial-carousel__container {
  width: 100%;
  max-width: 33.6875rem;
  overflow: hidden;
}
@media (max-width: 1063.98px) {
  .testimonial .testimonial__container .testimonial-carousel__container {
    max-width: 100%;
  }
}
.testimonial .testimonial__container .testimonial-carousel__container .testimonial-carousel__box {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}
.testimonial .testimonial__container .testimonial-carousel__container .testimonial-carousel {
  margin-bottom: 2.5rem;
}
@media (max-width: 1063.98px) {
  .testimonial .testimonial__container .testimonial-carousel__container .testimonial-carousel {
    margin-bottom: 0rem;
  }
}
.testimonial .testimonial__container .testimonial-carousel__container .testimonial-carousel .testimonial-carousel__items {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  max-width: 31.25rem;
  transition: all linear 0.4s;
}
@media (max-width: 1063.98px) {
  .testimonial .testimonial__container .testimonial-carousel__container .testimonial-carousel .testimonial-carousel__items {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .testimonial .testimonial__container .testimonial-carousel__container .testimonial-carousel .testimonial-carousel__items {
    width: 100%;
    max-width: 20.375rem;
  }
}
@media (max-width: 374.98px) {
  .testimonial .testimonial__container .testimonial-carousel__container .testimonial-carousel .testimonial-carousel__items {
    width: 100%;
    max-width: 20rem;
  }
}
.testimonial .testimonial__container .testimonial-carousel__container .testimonial-carousel .testimonial-carousel__items.slick-active {
  opacity: 1;
  visibility: visible;
}
.testimonial .testimonial__container .testimonial-carousel__container .testimonial-carousel .testimonial-carousel__detail {
  color: #E5E6ED;
  margin-bottom: 1rem;
}
@media (max-width: 374.98px) {
  .testimonial .testimonial__container .testimonial-carousel__container .testimonial-carousel .testimonial-carousel__detail {
    width: 100%;
    max-width: 17.5rem;
    margin: 0 auto;
  }
}
.testimonial .testimonial__container .testimonial-carousel__container .testimonial-carousel .testimonial-carousel__author {
  color: #A5ADCF;
}
@media (max-width: 374.98px) {
  .testimonial .testimonial__container .testimonial-carousel__container .testimonial-carousel .testimonial-carousel__author {
    width: 100%;
    max-width: 17.5rem;
    margin: 0 auto;
  }
}
.testimonial .testimonial__container .testimonial-carousel__container .slick__pagination {
  position: relative;
}
@media (max-width: 1063.98px) {
  .testimonial .testimonial__container .testimonial-carousel__container .slick__pagination {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.testimonial .testimonial__container .testimonial-carousel__container .slick__pagination .testimonial-carousel__arrows {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
@media (max-width: 1063.98px) {
  .testimonial .testimonial__container .testimonial-carousel__container .slick__pagination .testimonial-carousel__arrows {
    max-width: 23.4375rem;
    justify-content: space-between;
    margin: 0 auto;
  }
}
.testimonial .testimonial__container .testimonial-carousel__container .slick__pagination .testimonial-carousel__arrows .slick-arrow img {
  width: 2rem;
  height: 2rem;
}
.testimonial .testimonial__container .testimonial-media {
  position: relative;
  width: 100%;
  min-height: 23.75rem;
  max-width: 32rem;
}
@media (max-width: 1063.98px) {
  .testimonial .testimonial__container .testimonial-media {
    grid-row: 2/3;
    margin: 0 auto;
  }
}
@media (max-width: 767.98px) {
  .testimonial .testimonial__container .testimonial-media {
    min-height: 15.25rem;
    max-width: 20.5rem;
  }
}
.testimonial .testimonial__container .testimonial-media .testimonial-media__items {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  background: rgba(47, 50, 65, 0.5);
  border: none;
  border-radius: 100%;
  font-weight: 700;
  line-height: 1.1;
  color: #7D7D7D;
  margin: 0;
  position: absolute;
  overflow: hidden;
}
.testimonial .testimonial__container .testimonial-media .testimonial-media__items.active {
  width: 15.3125rem;
  height: 15.3125rem;
  border: 2px solid #246CF9;
  filter: drop-shadow(0px 34px 104px rgba(16, 16, 16, 0.85));
  font-size: 2.5rem;
  color: #CC2229;
  top: 3.6875rem;
  left: 0;
}
@media (max-width: 767.98px) {
  .testimonial .testimonial__container .testimonial-media .testimonial-media__items.active {
    width: 9.8125rem;
    height: 9.8125rem;
    left: 0;
    top: 2.375rem;
    font-size: 1.625rem;
  }
}
@media (max-width: 374.98px) {
  .testimonial .testimonial__container .testimonial-media .testimonial-media__items.active {
    top: 3.125rem;
  }
}
.testimonial .testimonial__container .testimonial-media .testimonial-media__items.next {
  width: 6.9375rem;
  height: 6.9375rem;
  font-size: 1.3125rem;
  top: 0;
  right: 11.6875rem;
}
@media (max-width: 767.98px) {
  .testimonial .testimonial__container .testimonial-media .testimonial-media__items.next {
    width: 4.4375rem;
    height: 4.4375rem;
    top: 0;
    right: 7.5rem;
    font-size: 0.875rem;
  }
}
@media (max-width: 374.98px) {
  .testimonial .testimonial__container .testimonial-media .testimonial-media__items.next {
    right: 6rem;
    font-size: 0.8125rem;
  }
}
.testimonial .testimonial__container .testimonial-media .testimonial-media__items.next-2 {
  width: 11.5625rem;
  height: 11.5625rem;
  font-size: 1.875rem;
  right: 0;
  top: 6rem;
}
@media (max-width: 767.98px) {
  .testimonial .testimonial__container .testimonial-media .testimonial-media__items.next-2 {
    width: 7.40625rem;
    height: 7.40625rem;
    right: 0;
    top: 3.8125rem;
    font-size: 1.25rem;
  }
}
.testimonial .testimonial__container .testimonial-media .testimonial-media__items.prev {
  width: 5.8125rem;
  height: 5.8125rem;
  font-size: 0.9375rem;
  right: 11.0625rem;
  bottom: 0;
}
@media (max-width: 767.98px) {
  .testimonial .testimonial__container .testimonial-media .testimonial-media__items.prev {
    width: 3.71875rem;
    height: 3.71875rem;
    right: 7.0625rem;
    bottom: 0;
    font-size: 0.625rem;
  }
}
@media (max-width: 374.98px) {
  .testimonial .testimonial__container .testimonial-media .testimonial-media__items.prev {
    right: 6.25rem;
  }
}

.mobile-platform {
  position: relative;
  padding: 6rem 0;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .mobile-platform {
    padding: 4rem 0 5.1875rem;
  }
}
.mobile-platform .mobile-platform__background {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 90rem;
  overflow: hidden;
  pointer-events: none;
  margin: 0 auto;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .mobile-platform .mobile-platform__background {
    display: none;
  }
}
.mobile-platform .mobile-platform__background.mobile-platform__background--grid {
  top: 0rem;
}
.mobile-platform .mobile-platform__background.mobile-platform__background--grid .mobile-platform__grid-bg {
  position: relative;
  left: -2.5rem;
  display: block;
  margin: 0 auto;
  max-width: 72.25rem;
}
.mobile-platform .mobile-platform__background.mobile-platform__background--pattern {
  top: -8.125rem;
}
@media (max-width: 1199.98px) {
  .mobile-platform .mobile-platform__background.mobile-platform__background--pattern {
    top: -3.125rem;
  }
}
.mobile-platform .mobile-platform__background.mobile-platform__background--pattern .mobile-platform__pattern-bg {
  position: relative;
  right: -2.4375rem;
  margin-left: auto;
  display: block;
  max-width: 28rem;
}
.mobile-platform .mobile-platform__background--mobile {
  display: none;
}
@media (max-width: 767.98px) {
  .mobile-platform .mobile-platform__background--mobile {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 33.75rem;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    z-index: -1;
  }
}
.mobile-platform .mobile-platform__background--mobile.mobile-platform__background--grid {
  top: 0rem;
}
.mobile-platform .mobile-platform__background--mobile.mobile-platform__background--grid .mobile-platform__grid-bg {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.mobile-platform .mobile-platform__background--mobile.mobile-platform__background--pattern {
  top: 4.75rem;
  right: 0;
  left: unset;
  margin: 0;
  margin-left: auto;
}
.mobile-platform .mobile-platform__background--mobile.mobile-platform__background--pattern .mobile-platform__pattern-bg {
  position: relative;
  margin-left: auto;
  display: block;
  width: 100%;
  max-width: 16.5rem;
}
.mobile-platform .mobile-platform__container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 31.9375rem);
  gap: 3.125rem;
  align-items: center;
}
@media (max-width: 1199.98px) {
  .mobile-platform .mobile-platform__container {
    gap: 2.5rem;
  }
}
@media (max-width: 1063.98px) {
  .mobile-platform .mobile-platform__container {
    gap: 1rem;
  }
}
@media (max-width: 991.98px) {
  .mobile-platform .mobile-platform__container {
    grid-template-columns: 1fr;
  }
}
.mobile-platform .mobile-platform__container .mobile-platform__illustration {
  display: block;
  width: 100%;
  max-width: 34.3125rem;
  height: auto;
  grid-row: 1/3;
}
@media (max-width: 991.98px) {
  .mobile-platform .mobile-platform__container .mobile-platform__illustration {
    grid-row: 2/3;
    margin: 1.5rem auto 0;
  }
}
.mobile-platform .mobile-platform__container .mobile-platform__illustration img {
  width: 100%;
}
@media (max-width: 991.98px) {
  .mobile-platform .mobile-platform__container .mobile-platform__content {
    text-align: center;
    margin: 0 auto;
  }
}
.mobile-platform .mobile-platform__container .mobile-platform__content .mobile-platform__title {
  max-width: 30.25rem;
  margin-bottom: 1.5rem;
  color: #FFFFFF;
}
@media (max-width: 767.98px) {
  .mobile-platform .mobile-platform__container .mobile-platform__content .mobile-platform__title {
    margin-bottom: 1rem;
  }
}
.mobile-platform .mobile-platform__container .mobile-platform__content .mobile-platform__subtitle {
  max-width: 25.125rem;
  color: #E5E6ED;
}
@media (max-width: 991.98px) {
  .mobile-platform .mobile-platform__container .mobile-platform__content .mobile-platform__subtitle {
    margin: 0 auto;
  }
}
@media (max-width: 767.98px) {
  .mobile-platform .mobile-platform__container .mobile-platform__content .mobile-platform__subtitle {
    margin: 0 auto 1.5rem;
  }
}
.mobile-platform .mobile-platform__container .mobile-platform__download {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 0.5rem;
}
@media (max-width: 1199.98px) {
  .mobile-platform .mobile-platform__container .mobile-platform__download {
    margin-top: 0rem;
  }
}
@media (max-width: 991.98px) {
  .mobile-platform .mobile-platform__container .mobile-platform__download {
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 991.98px) {
  .mobile-platform .mobile-platform__container .mobile-platform__download {
    gap: 1rem;
  }
}
.mobile-platform .mobile-platform__container .mobile-platform__download .mobile-platform__download-button {
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 479.98px) {
  .mobile-platform .mobile-platform__container .mobile-platform__download .mobile-platform__download-button {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
}
.mobile-platform .mobile-platform__container .mobile-platform__download .mobile-platform__download-button .btn {
  background: transparent !important;
}
@media (max-width: 479.98px) {
  .mobile-platform .mobile-platform__container .mobile-platform__download .mobile-platform__download-button .btn {
    width: 100%;
  }
}

.cta {
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .cta {
    padding-top: 0.8125rem;
  }
}
.cta .cta__background {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 90rem;
  overflow: hidden;
  pointer-events: none;
  margin: 0 auto;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .cta .cta__background {
    display: none;
  }
}
.cta .cta__background.cta__background--pattern {
  top: 5.625rem;
  left: 0;
}
.cta .cta__background.cta__background--pattern .cta__pattern-bg {
  left: 0;
  margin-right: auto;
  position: relative;
  display: block;
  max-width: 34.6875rem;
}
.cta .cta__background--mobile {
  display: none;
}
@media (max-width: 767.98px) {
  .cta .cta__background--mobile {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 33.75rem;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    z-index: -1;
  }
}
.cta .cta__background--mobile.cta__background--pattern {
  top: -0.5rem;
  left: 0;
  right: unset;
  margin: 0;
  margin-right: auto;
}
.cta .cta__background--mobile.cta__background--pattern .cta__pattern-bg {
  position: relative;
  margin-right: auto;
  display: block;
  width: 100%;
  max-width: 13.75rem;
}
.cta .cta__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cta .cta__title {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  max-width: 42.5rem;
  color: #FFFFFF;
}
@media (max-width: 767.98px) {
  .cta .cta__title {
    font-size: 1.75rem;
  }
}
.cta .cta__subtitle {
  color: #E5E6ED;
  max-width: 31.25rem;
}
@media (max-width: 767.98px) {
  .cta .cta__subtitle {
    font-size: 0.875rem;
  }
}
.cta .mini-form {
  position: relative;
  max-width: 28.875rem;
  width: 100%;
}
.cta .mini-form .mini-form__input {
  width: 100%;
}